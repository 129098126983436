<template>
  <Teleport to="body">
    <header class="site-header-home">
      <div class="corner -top-left">
        <NuxtLink class="logo" to="/" aria-label="Home">
          <span class="text">Home</span>
          <img
            height="141"
            width="400"
            src="/commesoie_2.webp"
            class="image"
            alt="Comme Soie Logo"
          />
        </NuxtLink>
      </div>
      <div class="corner -bottom-left">
        <p class="claim">Food Styling Concepts</p>
      </div>
      <div class="column">
        <nav class="main-navigation">
          <ul class="list">
            <li class="item" v-for="i in links" :key="i.id">
              <NuxtLink class="link link-navigation" :to="`/${i.link.slug}`"
                >{{ i.link.title }}
              </NuxtLink>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  </Teleport>
</template>

<script setup lang="ts">
const { data } = await useAsyncGql({
  operation: 'GetMainNavigation',
  options: {
    immediate: true,
    server: true
  }
});

const links = data.value?.mainNavigation?.links ?? [];
</script>

<style scoped lang="scss">
.site-header-home {
  position: fixed;
  inset: 0;
  color: var(--color-black);
  justify-content: space-between;
  z-index: 120;
  mix-blend-mode: difference;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;

  * {
    user-select: none;
  }

  @media (--vs) {
    padding: 1rem;
  }

  @media (--vl) {
    padding: 2.5rem;
  }

  > .corner {
    &.-top-left {
      grid-area: 1 / 1 / 2 / 2;

      > .logo {
        @media (--vs) {
          width: 7.1875rem;
        }
        @media (--vl) {
          width: 10rem;
        }

        > .text {
          font-size: 0;
          position: absolute;
        }

        > .image {
          height: auto;

          @media (--vs) {
            width: 7.1875rem;
          }

          @media (--vl) {
            width: 10rem;
          }
        }
      }
    }

    &.-bottom-left {
      grid-area: 2 / 1 / 3 / 2;
      align-self: end;

      > .claim {
        color: var(--color-white);

        @media (--vs) {
          @include text-sm-display();

          padding: 0.5rem 0 0.25rem 0;
        }
        @media (--vl) {
          @include text-sm-display();

          padding: 1rem 0 0 0.62rem;
        }
      }
    }
  }

  > .column {
    grid-area: 1 / 2 / 3 / 3;
    justify-self: end;
    height: 100%;
  }
}

.main-navigation {
  height: 100%;

  > .list {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    height: 100%;

    @media (--vs) {
      gap: 2rem;
    }
    @media (--vl) {
      gap: 2.5rem;
    }

    > .item {
      > .link {
        @include text-sm-display();

        text-decoration: none;
        color: var(--color-white);
      }
    }
  }
}
</style>
